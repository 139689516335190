var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-news"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("岳麓山大学科技城：强化科技人才支撑 打造湖南创新高地")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"pagination"},[_c('div',{staticClass:"a-more"},[_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails1')}}},[_vm._v("上一条：中共中央政治局常委、国务院副总理韩正来我公司视察")]),_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails3')}}},[_vm._v("下一条：大科城软件产业联盟拍了拍你，新云网智慧 亮剑！")])]),_c('el-button',{attrs:{"plain":""},on:{"click":function($event){return _vm.backGo()}}},[_vm._v(" 返回 ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-setting"},[_c('h3',{staticClass:"time"},[_vm._v(" 发布时间： 2020-06-03 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"30px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news2/news1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"20px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news2/news2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content"},[_c('p',{staticStyle:{"text-align":"justify","text-indent":"36px"}},[_vm._v(" 在中南大学科技园研发总部，15 位联络员负责对接园区 51 家企业，了解了企业需求，精准服务。湖南新云网是一家以“透明计算”理论为依托的高科技企业，业务涉及教育、医疗等多个领域。为了解决孵化新项目的资金问题，联络员第一时间和银行等部门沟通协调，从首次走访企业到一亿元综合 授信审批完成，只花费五个工作日。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("../../assets/images/news/news2/news3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content"},[_c('p',{staticStyle:{"text-align":"justify","text-indent":"36px"}},[_vm._v(" 湖南新云网科技有限公司 副总经理 杨建国：在国家政策、地方政策对企业的支持方面，给了我们很多及时的信息、让企业和政府的交流成本大大下降。 ")])])
}]

export { render, staticRenderFns }